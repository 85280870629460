<template>
    <slot />
</template>

<script setup>
    import { AdditiveBlending, Color, CylinderGeometry, Mesh, Vector2 } from 'three';
    import { computed, ref, watch, watchEffect } from 'vue';

    import glslDraw from '@resn/gozer-glsl/shapes/draw.glsl';
    import { CustomMaterial } from '@resn/gozer-three/materials';
    import { usePane, useRaf, useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import glslLighten from '@/libs/lygia/color/blend/lighten.glsl';
    import glslRotate from '@/libs/lygia/space/rotate.glsl';
    import { useBloom } from '~/composables/useBloom';
    import { PALETTE_THEME } from '~/core/constants';
    import { useGlobalAssets } from '~/providers/GlobalAssets';

    const props = defineProps({
        colorPalette: { defaut: PALETTE_THEME[0] },
    });

    const wipeProgress = ref(0);
    const speed = ref(0.12);
    const trailAngle = ref(-1.13);
    const color1 = ref('#a9adfa');
    const color2 = ref('#b9e7f0');
    const noiseWidth = ref(0.08);
    const noiseHeight = ref(2.4);
    const shapeThreshold1 = ref(0.28);
    const shapeThreshold2 = ref(0.35);
    // const shapeThreshold1 = ref(0.65);
    // const shapeThreshold2 = ref(0.57);
    const shapeBleed = ref(0.05);
    const visible = computed(() => wipeProgress.value > 0);

    const bloom = useBloom();
    watch(
        () => bloom.pass.value,
        (pass) => {
            pass.selection?.add(mesh);
        }
    );

    const geo = new CylinderGeometry(0.5, 0.5, 2, 16, 1, true);
    const material = new CustomMaterial({
        uniforms: {
            uTime: 0,
            uWipeProgress: 0,
            uSpeed: 1,
            uTrailAngle: 1,
            uNoiseMap1: null,
            uNoiseMap2: null,
            uShapeThreshold1: 0,
            uShapeThreshold2: 0,
            uShapeBleed: 0,
            uColor1: new Color(),
            uColor2: new Color(),
            uNoiseSize: new Vector2(),
            uColorPalette: props.colorPalette,
        },
        vs: /* glsl */ `
            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 vViewDir;

            void main() {
                vUv = uv;

                // Pass the transformed normal and view direction to the fragment shader
                vNormal = normalize(normalMatrix * normal); // Transform normal to view space
                vec4 viewPosition = modelViewMatrix * vec4(position, 1.0);
                vViewDir = normalize(-viewPosition.xyz); // View direction in view space

                gl_Position = projectionMatrix * viewPosition;
            }
        `,
        fs: /* glsl */ `
            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 vViewDir;

            uniform float uTime;
            uniform float uWipeProgress;
            uniform float uSpeed;
            uniform float uTrailAngle;
            uniform sampler2D uNoiseMap1;
            uniform sampler2D uNoiseMap2;
            uniform vec3 uColor1;
            uniform vec3 uColor2;
            uniform float uShapeThreshold1;
            uniform float uShapeThreshold2;
            uniform float uShapeBleed;
            uniform vec2 uNoiseSize;

            #define NUM_COLORS 3
            uniform vec3 uColorPalette[NUM_COLORS];

            ${glslDraw}
            ${glslRotate}
            ${glslLighten}

            void main() {
                vec2 uv = vUv;
                
                // Colors
                vec3 color1 = uColorPalette[0];
                vec3 color2 = uColorPalette[1];
                vec3 color3 = uColorPalette[2];

                float fresnelPower = 2.0;
                float fresnel = 1.0 - pow(1.0 - dot(vNormal, vViewDir), fresnelPower);
                
                float offset = mix(1.0, -2.0, uWipeProgress);
                float wipe = fill(length(uv.x + offset), 1.0, 0.05);
                float waveOffset = sin(uv.x * 14.0) * 0.05;
                float inProgress = mix(0.3, 1.0, uWipeProgress);

                // Noise 1
                vec2 noiseUv = rotate(uv, uTrailAngle);
                noiseUv.y += waveOffset;
                noiseUv = noiseUv * uNoiseSize;
                noiseUv.x -= uTime * uSpeed;
                float noise = texture2D(uNoiseMap1, noiseUv).r;

                // Noise 2
                vec2 noise2Uv = vUv * 1.5 - vec2(uTime * uSpeed * 5.0);
                float noise2 = texture2D(uNoiseMap2, noise2Uv).r;
                
                // Noise Final
                float noiseFinal = mix(noise, noise2, 0.35);

                float xEdgeDist = 0.3;
                float yEdgeDist = 0.4;
                float edgeAlpha = smoothstep(0.0,xEdgeDist, vUv.x) * smoothstep(1.0, 1.0 - xEdgeDist, vUv.x);
                edgeAlpha *= smoothstep(0.0,yEdgeDist, vUv.y) * smoothstep(1.0, 1.0 - yEdgeDist, vUv.y);

                float sdf = noiseFinal * edgeAlpha * fresnel * inProgress;

                // float shape1 = step(uShapeThreshold1, sdf);
                // float shape2 = step(uShapeThreshold2, sdf);

                // sdf = 1.0 - sdf;
                float shape1 = fill(uShapeThreshold1, sdf, uShapeBleed);
                float shape2 = fill(uShapeThreshold2, sdf, uShapeBleed);

                // vec3 shapeColor = mix(uColor1, uColor2, shape2);
                vec3 shapeColor = mix(color2, blendLighten(color2, vec3(1.0), 0.5), shape2) * 0.8;
                float alpha = shape1;
                
                gl_FragColor = vec4(shapeColor, alpha);
                // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
                // gl_FragColor = vec4(vec3(wipe), 1.0);
            }
        `,
        options: {
            // side: DoubleSide,
            depthTest: false,
            depthWrite: false,
            blending: AdditiveBlending,
            name: 'VortexMaterial',
        },
    });
    const mesh = new Mesh(geo, material);
    mesh.renderOrder = 4;
    mesh.position.set(0, 0, 0.1);
    mesh.rotation.set(0, Math.PI, 0);
    mesh.scale.setScalar(1.3);

    useGlobalAssets(({ data }) => {
        material.uniforms.uNoiseMap1.value = data.noise1;
        material.uniforms.uNoiseMap2.value = data.pNoise;
    });

    useThreeObject(mesh, { name: 'Vortex' });

    const show = () => {
        const tl = gsap.timeline();
        tl.fromTo(wipeProgress, { value: 0 }, { value: 1, duration: 0.4, ease: 'sine.out' });
        tl.to(wipeProgress, { value: 0, duration: 0.4, ease: 'sine.in' }, '+=0');
    };

    watchEffect(() => {
        mesh.visible = visible.value;
        material.uniforms.uShapeThreshold1.value = shapeThreshold1.value;
        material.uniforms.uShapeThreshold2.value = shapeThreshold2.value;
        material.uniforms.uShapeBleed.value = shapeBleed.value;
        material.uniforms.uSpeed.value = speed.value;
        material.uniforms.uTrailAngle.value = trailAngle.value;
        material.uniforms.uColor1.value.set(color1.value);
        material.uniforms.uColor2.value.set(color2.value);
        material.uniforms.uNoiseSize.value.set(noiseWidth.value, noiseHeight.value);
    });

    watchEffect(() => {
        material.uniforms.uWipeProgress.value = wipeProgress.value;
    });

    useRaf(({ timestamp }) => {
        material.uniforms.uTime.value = timestamp / 1000;
    });

    defineExpose({ show });

    const options = { min: 0, max: 1, step: 0.01 };

    usePane(
        [
            { name: 'visible', value: visible },
            { name: 'wipeProgress', value: wipeProgress, options },
            { name: 'color1', value: color1 },
            { name: 'color2', value: color2 },
            { name: 'speed', value: speed, options: { min: 0, max: 0.2, step: 0.001 } },
            { name: 'trailAngle', value: trailAngle, options: { min: -2, max: 2, step: 0.01 } },
            { name: 'shapeThreshold1', value: shapeThreshold1, options },
            { name: 'shapeThreshold2', value: shapeThreshold2, options },
            { name: 'shapeBleed', value: shapeBleed, options: { min: 0, max: 0.1, step: 0.001 } },
            { name: 'noiseWidth', value: noiseWidth, options: { min: 0, max: 1, step: 0.01 } },
            { name: 'noiseHeight', value: noiseHeight, options: { min: 0, max: 5, step: 0.01 } },
        ],
        { title: 'Vortex', expanded: false }
    );
</script>
